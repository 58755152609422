import React, { useRef } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";

const CustomGeometry = () => {
  const meshRef = useRef();

  useFrame(() => {
    meshRef.current.rotation.x += 0.01;
    meshRef.current.rotation.y += 0.01;
  });

  const count = 500;
  const positions = new Float32Array(count * 3 * 3);
  for (let i = 0; i < count * 3 * 3; i++) {
    positions[i] = (Math.random() - 0.5) * 2;
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

  return (
    <mesh ref={meshRef} geometry={geometry} scale={(4, 4, 4)}>
      <meshBasicMaterial color="#faf0be" wireframe={true} />
    </mesh>
  );
};

const App = () => {
  return (
    <Canvas
      camera={{
        position: [0, 0, 10],
        fov: 75,
      }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <CustomGeometry />
    </Canvas>
  );
};

export default App;
