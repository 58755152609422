import "./App.css";
import RenderCube from "./components/RenderCube";
import RenderRandomCube from "./components/RenderRandomCube";

function App() {
  return (
    <div className="cube">
      <RenderRandomCube className="rendered"></RenderRandomCube>
      <p className="coming-soon">Coming Soon</p>
    </div>
  );
}

export default App;
